
    import {Options, Vue} from "vue-class-component";

    @Options({
        props: {
            item: Object,
        },
        watch: {
            $route() {
                this.current(this.$route.path);
            }
        },
    })
    export default class NavItem extends Vue {
        item!: any;
        active = false;

        mounted() {
            this.$nextTick(function () {
                this.current(this.$route.path)
            });
        }

        current(path: string) {
            this.active = !!(this.item.path && this.item.path == path);
        }
    }
