import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c65c77b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "unread-num"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.item.hide)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['channel',_ctx.active ? 'action' : '']),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.item.event && _ctx.item.event(...args)), ["stop"]))
      }, [
        (_ctx.item.path)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: _ctx.item.path
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass(_ctx.item.icon)
                }, null, 2)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: _normalizeClass(_ctx.item.icon)
            }, null, 2)),
        (_ctx.item.unread)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.item.unread), 1))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}