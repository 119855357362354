import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6a2d796"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "first-nav" }
const _hoisted_3 = { class: "avatar" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "setting" }
const _hoisted_6 = {
  key: 0,
  class: "setting-menu"
}
const _hoisted_7 = { class: "second-nav" }
const _hoisted_8 = {
  key: 0,
  class: "third-nav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavItem = _resolveComponent("NavItem")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.userinfo?.avatar
        }, null, 8, _hoisted_4)
      ]),
      _createVNode(_component_NavItem, {
        item: _ctx.menu.message
      }, null, 8, ["item"]),
      _createVNode(_component_NavItem, {
        item: _ctx.menu.contact
      }, null, 8, ["item"]),
      _createVNode(_component_NavItem, {
        item: _ctx.menu.me
      }, null, 8, ["item"]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.showSettingMenu)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "setting-item",
                onClick: _cache[0] || (_cache[0] = _withModifiers(()=>{}, ["stop"]))
              }, "设置"),
              _createElementVNode("div", {
                class: "setting-item",
                onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)), ["stop"]))
              }, "退出登录")
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_NavItem, {
          item: _ctx.menu.setting
        }, null, 8, ["item"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_view, { name: "second-nav" })
    ]),
    (!_ctx.is_mobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_router_view, { name: "third-nav" })
        ]))
      : _createCommentVNode("", true)
  ]))
}